import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "The History",
    paragraph: (
      <p>
        As a Norwegian startup company, Equifjord has achieved a valuation of
        over one billion dollars, earning us the distinction of being referred
        to as a "hidden unicorn" that has rapidly risen to become a dominant
        player in our industry. Utilizing cutting-edge artificial intelligence
        techniques based on state-of-the-art research methods, we develop
        technological solutions to combat pollution and preserve the earth's
        natural resources. Our commitment to making a positive impact on society
        is evident in our efforts to keep coastlines and seabeds free of litter
        and pollution, as well as our dedication to preventing the release of CO
        <sub>2</sub> into the atmosphere.
      </p>
    ),
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-01.svg")}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">The Problem</h4>
                  <p className="m-0 text-sm">
                    Pollution and the release of CO<sub>2</sub> into the
                    atmosphere are major issues facing our planet today. These
                    problems not only harm the natural environment but also have
                    a negative impact on human health and the economy. Litter
                    and pollution in coastlines and seabeds is also a
                    significant issue, affecting marine life and hindering
                    economic activities such as fishing and tourism.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-02.svg")}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Today's Solution</h4>
                  <p className="m-0 text-sm">
                    Currently, there are various efforts being made to address
                    these issues, such as government regulations and the use of
                    traditional technologies. However, these solutions are often
                    costly and have limited effectiveness in addressing the
                    scale of the problem.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Equifjord's Solution</h4>
                  <p className="m-0 text-sm">
                    Equifjord offers a revolutionary solution by utilizing
                    artificial intelligence based on state-of-the-art research
                    methods to produce technological solutions to keep the earth
                    safe from pollution. Our contribution to society keeps the
                    coast and seabed free of litter and pollution, while
                    ensuring that the CO<sub>2</sub> never reaches the
                    atmosphere. We are committed to making a positive impact on
                    society and preserving the earth's natural resources for
                    future generations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
