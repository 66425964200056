import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Customer testimonials",
    paragraph:
      "Our customers have seen firsthand the positive impact of our solutions on their business and the environment. Read on to see what they have to say about working with Equifjord.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — We at XYZ Seafood have been using Equifjord's solutions to
                    keep our coastlines and seabeds free of litter and
                    pollution. Their technology is innovative and effective, and
                    it has had a positive impact on our business, allowing us to
                    fish in a cleaner and safer environment. We highly recommend
                    Equifjord to any company looking to improve their
                    environmental impact.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    John Smith
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>XYZ Seafood</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — As a leading provider of transportation services, we at
                    ABC Logistics were looking for a solution to reduce our CO
                    <sub>2</sub> emissions. Equifjord's technology has exceeded
                    our expectations, allowing us to significantly reduce our
                    carbon footprint and improve our sustainability. Their team
                    was also very helpful and responsive throughout the
                    implementation process.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Sarah Johnson
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>ABC Logistics CEO</a>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Our company, GreenTech Industries, specializes in clean
                    energy solutions. We have been working with Equifjord to
                    find ways to reduce the amount of pollution generated by our
                    production process. The results have been impressive, their
                    technology has helped us to reduce our pollution output by
                    40% and improve our overall sustainability. We are very
                    satisfied with the partnership and look forward to continue
                    working with Equifjord.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    David Brown
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>GreenTech Industries</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
