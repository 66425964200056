import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "A solution that just works",
    paragraph:
      "Equifjord's AI-based solutions effectively combat pollution, preserving the earth's natural resources, and keep coastlines and seabeds pollution-free. Our commitment to making a positive impact on society is evident. Trust our solutions.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  HEALTHY FOCUS
                </div>
                <h3 className="mt-0 mb-12">Society and the Environment</h3>
                <p className="m-0">
                  Equifjord's solutions are having a significant impact on
                  society and the environment, particularly in the areas of
                  pollution control and CO<sub>2</sub> emissions reduction.
                  Through the use of cutting-edge artificial intelligence and
                  data analysis techniques, we have been able to effectively
                  address some of the most pressing environmental challenges
                  facing our planet. As an example, our technology has helped to
                  reduce the amount of litter and pollution in coastlines and
                  seabeds by 50%, providing a safer and cleaner environment for
                  marine life and supporting economic activities such as fishing
                  and tourism. Additionally, our solutions have led to a
                  reduction of CO<sub>2</sub> emissions by 30%, helping to
                  mitigate the effects of climate change and promoting a cleaner
                  and healthier environment for all.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/coastal_pollution_1.jpg")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  SCIENCE BASED
                </div>
                <h3 className="mt-0 mb-12">Data-driven Insights</h3>
                <p className="m-0">
                  At Equifjord, we use data-driven insights to inform our
                  artificial intelligence-based solutions for combating
                  pollution and preserving the earth's natural resources. We
                  collect and analyze large amounts of data related to
                  pollution, climate change and other environmental factors.
                  This data is then used to develop and improve our technology,
                  allowing us to make more accurate predictions and make more
                  effective decisions. By constantly monitoring and analyzing
                  data, we are able to optimize our solutions and ensure that
                  they are as effective as possible in addressing the
                  environmental challenges facing our planet.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/data_driven_2.jpg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  STATE-OF-THE-ART
                </div>
                <h3 className="mt-0 mb-12">The Science Behind our Solutions</h3>
                <p className="m-0">
                  The science behind Equifjord's solutions is based on a
                  combination of cutting-edge artificial intelligence techniques
                  and advanced data analysis methods. We use state-of-the-art
                  machine learning algorithms to analyze large datasets of
                  environmental data, such as ocean currents, weather patterns,
                  and pollution levels. This allows us to make more accurate
                  predictions and make more effective decisions when developing
                  our technology. Additionally, we employ the use of computer
                  vision and natural language processing to extract insights
                  from unstructured data such as images and texts. These methods
                  allow us to extract valuable information from sources that
                  would otherwise be difficult to utilize. Our solutions are
                  also driven by recent scientific breakthroughs such as
                  reinforcement learning and deep learning to optimize and make
                  our technology more efficient. All of these techniques, when
                  combined, allow us to stay at the forefront of addressing
                  environmental challenges and providing effective solutions.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/science_solutions_3.jpg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
