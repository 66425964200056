import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Founders = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "How It All Started",
    paragraph: (
      <p>
        The story of Equifjord began in a small, rundown garage on the outskirts
        of a small Norwegian town. A group of young, ambitious friends, all with
        a passion for technology and the environment, came together with the
        goal of finding a way to make a real difference in the world. They spent
        countless hours tinkering with various ideas, experimenting with
        different technologies, and pouring over mountains of scientific
        research.
        <br />
        <br />
        One day, while out on a hike in the nearby fjords, the group stumbled
        upon an area that had been devastated by pollution. The sight of the
        once-beautiful landscape marred by litter and debris was a turning point
        for them. They knew they had to do something to help. They began to
        focus their efforts on finding a solution to the pollution problem, and
        soon they had developed a prototype for a device that could effectively
        clean up coastlines and seabeds.
        <br />
        <br />
        But the group didn't stop there. They knew that pollution was just one
        part of the larger environmental challenges facing our planet. They
        continued to push the boundaries of what was possible, incorporating
        artificial intelligence and big data analysis into their technology. And
        so, Equifjord was born.
        <br />
        <br />
        Destined to succeed, the group at Equifjord recognized the importance of
        networking and learning from the best in the field. Realizing that their
        small hometown in Norway may not have all the resources and knowledge
        they needed, they decided to embark on a journey to Spain. They
        travelled to Spain to discuss business strategies with other experts and
        companies in the industry. They met with leading companies and
        individuals in the field of environmental technology and artificial
        intelligence. They learned about the latest trends, technologies and
        techniques, and used this knowledge to further improve and optimize
        their own solutions. This journey allowed them to bring new ideas and
        approaches to their work, and to position themselves as leaders in their
        field.
        <br />
        <br />
        The group faced many challenges in the early days, from financial
        struggles to skepticism from the scientific community. But they
        persevered, and soon they had attracted the attention of investors and
        industry leaders. Today, Equifjord is a thriving company, with its
        innovative solutions making a real impact on society and the
        environment.
        <br />
      </p>
    ),
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
        </div>
      </div>
    </section>
  );
};

Founders.propTypes = propTypes;
Founders.defaultProps = defaultProps;

export default Founders;
